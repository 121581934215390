import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bonitaet = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Bonität und Bonitätsprüfung – Was muss ich wissen?" showCalc={false} />
            <Article>
                <p>
                    Wenn es um die Finanzierung einer Immobilie geht ist es essentiell, gleich zu Beginn zu wissen, was
                    die entscheidenden Parameter bei der Vergabe von Krediten sind. Der vielleicht wichtigste ist die
                    Bonität. In diesem Artikel möchten wir dir deshalb kurz erklären, wie sich deine Bonität auf deinen
                    Kredit auswirken kann.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Bonität – Was ist das überhaupt?</h3>
                <p>
                    Stellst du eine Kreditanfrage, so wird die betreffende Bank zunächst deine Bonität prüfen. In diesem
                    Zusammenhang gibt es zwei Begriffe, die du dir merken solltest: Kreditfähigkeit und
                    Kreditwürdigkeit. „Kreditfähigkeit“ meint, dass du in der Lage bist, rechtswirksame Kreditverträge
                    abzuschließen, was in Österreich grundsätzlich mit der Vollendung des 18. Lebensjahres der Fall ist.
                    Als kreditwürdig wirst du hingegen nur dann betrachtet, wenn du aller Wahrscheinlichkeit nach in der
                    Lage bist, den gewährten Kredit auch zurückzuerstatten.
                </p>
                <p>
                    <strong>
                        Teste deine Kreditoptionen jetzt gleich in unserem miracl{" "}
                        <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                            Kreditrechner
                        </Link>
                        !
                    </strong>
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h3>Wie wird die Bonität ermittelt?</h3>
                <p>
                    Bei der Bearbeitung deines Kreditantrags schätzt die Bank deine Kreditwürdigkeit in einem
                    Ratingprozess ein. Hierbei wirst du einer bestimmten Bonitätsstufe zugeordnet – je höher diese ist,
                    umso wahrscheinlicher wird man dir die beantragte{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>{" "}
                    gewähren.
                </p>
                <p>
                    Es gibt in Österreich allerdings kein allgemein verbindliches Ratingsystem, auf das sich Banken bei
                    der Einstufung ihrer Kund*innen beziehen. Die Kreditinstitute gewichten die einzelnen Parameter auf
                    je unterschiedliche Weise, was deine Selbsteinschätzung als potenzielle Kreditnehmer*in entsprechend
                    erschwert.
                </p>
                <hr />

                <h3>Welche Dokumente werden bei der Bonitätsprüfung benötigt?</h3>
                <p>
                    Damit es überhaupt zu einer Bonitätsprüfung kommt, musst du bereits im Rahmen des Kreditantrags
                    folgende Dokumente vorlegen:
                </p>
                <ul>
                    <li>Ausweis zur Bestätigung der eigenen Identität</li>
                    <li>Nachweis über einen Wohnsitz in Österreich</li>
                    <li>Einkommensnachweis</li>
                    <li>Nachweis eines bestehenden Girokontos</li>
                </ul>
                <p>Im nächsten Schritt kommt es zur Bonitätsprüfung im eigentlichen Sinn.</p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h3>Welche Kriterien werden bei der Bonitätsprüfung berücksichtigt?</h3>
                <p>
                    Wurden die genannten Nachweise erbracht, so wird die Bonitätsprüfung eingeleitet, in der das
                    Kreditinstitut u.a. folgende Parameter in den Blick nimmt:
                </p>
                <ul>
                    <li>Familiensituation</li>
                    <li>bestehende Kredite und allgemeine Schuldensituation</li>
                    <li>Rücklagen und Vermögenswerte</li>
                    <li>Die Haushaltsrechnung (Einnahmen unter Abzug der Ausgaben)</li>
                    <li>
                        Dienstverhältnis (ist dein Dienstvertrag befristet? Bist du freiberuflich oder als Angestellte*r
                        tätig?)
                    </li>
                </ul>
                <p>
                    Bedauerlicherweise spielt auch dein aktueller Wohnsitz bei der Einschätzung deiner Bonität keine
                    geringe Rolle. Dieses Kriterium, das sich deiner Kontrolle größtenteils entzieht, macht die
                    mangelnde Objektivität des Prüfungsverfahrens deutlich – sind derart pauschale Aussagen über ganze
                    Wohngebiete doch zumindest problematisch.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Was ist ein Bonitätsranking?</h3>
                <p>
                    Je nach Gewichtung der genannten Parameter ergibt sich schließlich eine Kennzahl, die in einem
                    Bonitätsranking verortet wird. Ein solches Ranking könnte beispielsweise so aussehen:
                </p>
                <ul>
                    <li>100-199 – sehr geringes Risiko</li>
                    <li>200-299 –&nbsp; geringes Risiko</li>
                    <li>300-399 –&nbsp; durchschnittliches Risiko</li>
                    <li>400-499 –&nbsp; erhöhtes Risiko</li>
                    <li>500-599 –&nbsp; hohes Risiko</li>
                    <li>600-699 –&nbsp; sehr hohes Risiko</li>
                    <li>Ab 700 – Insolvenz</li>
                </ul>
                <p>
                    Je niedriger die Kennzahl, umso niedriger ist auch das Risiko für die Bank, dir einen Kredit zu
                    gewähren, und umso höher ist zugleich die Wahrscheinlichkeit, einen Kredit zu erhalten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt beraten lassen!
                    </a>
                </p>
                <hr />

                <h3>Die eigenen Möglichkeiten im Blick: Selbstauskunft bei KSV, CRIF, Bisnode & Co</h3>
                <p>
                    Die erste Anlaufstelle, wenn es um Auskünfte zur Bonität von Privatpersonen geht, ist der 1870
                    gegründete Kreditschutzverband – kurz KSV 1870. Dieser hat es sich zur Aufgabe gemacht,
                    personenbezogenen wirtschaftliche Daten – zu Zahlungsverhalten, Liquidität etc. – zu sammeln, die
                    Kreditinstituten im Rahmen einer Bonitätsprüfung zur Verfügung gestellt werden. Doch auch du bist
                    dazu berechtigt, deinen Eintrag einmal pro Jahr kostenlos{" "}
                    <a
                        href="https://www.ksv.at/fuer-privatpersonen/selbstauskunft"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        einzusehen
                    </a>
                    . Das gilt übrigens nicht nur für den KSV: Das Recht auf kostenlose jährliche Einsicht gilt auch für
                    anderen Datenbanken wie CRIF oder Bisnode.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Expertengespräch
                    </a>
                </p>
                <hr />

                <h3>5 Tipps zur Verbesserung deiner Bonität</h3>
                <p>
                    Du fragst dich vielleicht, ob es möglich ist, deine Bonität zu verbessern. Die Antwort lautet:
                    Grundsätzlich ja, doch meist nur auf lange Sicht – eine kurzfristige Verbesserung der eigenen
                    Bonität ist in den meisten Fällen leider nicht zu bewerkstelligen. Mit den folgenden 6 Tipps kannst
                    du jedoch sicherstellen, dass sich deine Bonität nach und nach verbessert und nachhaltig auf hohem
                    Niveau bleibt:
                </p>
                <ul>
                    <li>
                        Vermeide Zahlungsrückstände und Mahnungen – selbst bei vergleichsweise geringen Ausgaben wie
                        Telefon- oder Internetgebühren
                    </li>
                    <li>
                        Sieh von Ratenzahlungen ab – so manches Kreditinstitut wird das als einen Hinweis auf mangelnde
                        Liquidität auslegen
                    </li>
                    <li>Achte darauf, Schulden immer verlässlich und fristgerecht zu begleichen</li>
                    <li>
                        Biete der Bank Sicherheiten an (etwa eine{" "}
                        <Link to="/artikel/hypothekendarlehen/" target="_blank" rel="noreferrer noopener">
                            Hypothek
                        </Link>{" "}
                        , eine Lebensversicherung oder eine Erbschaft)
                    </li>
                    <li>
                        Stelle den Kreditantrag mit deinem*deiner Partner*in – sofern ihr beide über ein gesichertes
                        Einkommen verfügt, verringert auch ein gemeinsamer Kredit das Risiko eines Zahlungsausfalls
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Noch Fragen? Wir beraten dich gerne!</h3>
                <p>
                    Gerade bei einem so zentralen Thema wie der Bonität gilt: Frag im Zweifelsfall lieber nach! Bei
                    einem unverbindlichen Online-Gespräch beraten dich unsere Finanzexpert*innen zu allen Facetten der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    und finden mit dir ein Finanzierungsangebot, das wirklich zu dir passt – ganz bequem von zuhause
                    aus! Worauf wartest du noch? Lass von dir hören!
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bonitaet"}></BreadcrumbList>
            <ArticleStructuredData page={"bonitaet"} heading={"Bonität und Bonitätsprüfung – Was muss ich wissen?"} />
        </Layout>
    );
};

export default Bonitaet;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bonitaet", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
